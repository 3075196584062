import api from './api'

export const createAccount = payload => {
  return api.post('accounts/', payload, { skipAuth: true })
}

export const fetchAccount = () => {
  return api.get('accounts/my/')
}

export const updateAccount = payload => {
  return api.patch('accounts/my/', payload)
}

export const fetchAccountStats = () => {
  return api.get('accounts/my/stats/')
}

export const updateAccountPassword = payload => {
  return api.post('accounts/my/password/', payload)
}

export const sendForgotPasswordRequest = payload => {
  return api.post('accounts/forgot_password/', payload, { skipAuth: true })
}

export const sendResetPasswordRequest = payload => {
  return api.post('accounts/reset_password/', payload, { skipAuth: true })
}

export const logoutAccount = payload => {
  return api.post('accounts/my/logout/', payload)
}
