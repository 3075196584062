import api from './api'
import { makeQueryFilters } from '@/helpers/filters'

export const fetchOrders = filters => {
  return api.get(`orders/my/?${makeQueryFilters(filters)}`)
}

export const createOrder = order => {
  return api.post('orders/my/', order)
}

export const fetchShippingRates = (id, payload) => {
  return api.post(`orders/my/${id}/shipping_rates/`, payload)
}
