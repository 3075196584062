import api from './api'

export const updatePart = async (id, payload) => {
  if (payload.calculationId) {
    await api.post(`parts/${id}/apply-calc/`, {
      calculationId: payload.calculationId,
    })
  }
  return api.put(`parts/my/${id}/`, payload)
}

export const deletePart = (quoteId, partId) => {
  return api.delete(`quotes/my/${quoteId}/parts/${partId}/`)
}

export const checkPartThumbnail = partId => {
  return api.get(`parts/my/${partId}/thumbnail-link/`)
}

export const checkStreamicsStatus = partId => {
  return api.get(`parts/my/${partId}/streamics/`)
}

export const setPartManualQuotation = id => {
  return api.put(`parts/my/${id}/set-manual-quotation/`)
}

export const findPart = id => {
  return api.get(`parts/my/${id}/`)
}

export const checkIsStlExist = partId => {
  return api.get(`parts/my/${partId}/is-stl-exist/`)
}
