import api from './api'
import { makeQueryFilters } from '@/helpers/filters'

export const findQuote = id => {
  return api.get(`quotes/my/${id}/`)
}

export const updateQuote = (id, payload) => {
  return api.put(`quotes/my/${id}/`, payload)
}

export const fetchQuotes = filters => {
  return api.get(`quotes/my/?${makeQueryFilters(filters)}`)
}

export const createManualQuote = payload => {
  return api.post('quotes/my/', {
    ...payload,
    quoteType: 'Assisted',
  })
}

export const createInstantQuote = payload => {
  return api.post('quotes/my/', {
    ...payload,
    quoteType: 'Instant',
  })
}

export const addPartsToQuote = (id, partsIds) => {
  return api.put(`quotes/my/${id}/add-parts/`, {
    parts: partsIds,
  })
}

export const bulkDeleteParts = (id, partsIds) => {
  return api.delete(
    `quotes/my/${id}/bulk/delete-parts/?parts=${partsIds.join(',')}`
  )
}

export const bulkEditParts = (id, payload) => {
  return api.put(`quotes/my/${id}/bulk/edit/`, payload)
}

export const deleteQuote = id => {
  return api.delete(`quotes/my/${id}`)
}

export const paperlessCheckout = id => {
  return api.post(`quotes/my/${id}/paperless/checkout/`)
}

export const createPaperlessBasicQuote = payload => {
  return api.post(
    'quotes/paperless-basic-quote/',
    { ...payload },
    { skipAuth: true }
  )
}

export const createMinOrderFee = id => {
  return api.post(`quotes/my/${id}/min-order-fee/`)
}

export const deleteMinOrderFee = id => {
  return api.delete(`quotes/my/${id}/min-order-fee/`)
}

export const partRequest = payload => {
  return api.post('quotes/part-request/', payload)
}
