<template>
  <div class="app-container">
    <template v-if="maintenance">
      <MaintenanceOverlay />
    </template>

    <template v-else-if="error">
      <ErrorOverlay :error="error" />
    </template>

    <template v-else>
      <transition name="fade" appear mode="out-in">
        <AppWrapper v-if="initialized" />
        <AppPreloader v-else />
      </transition>
    </template>
    <ForceResetPasswordModal />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import * as storage from '@/auth/helpers/storage'
import { handleErrors } from '@/core/helpers/error'

import AppWrapper from '@/core/components/layout/AppWrapper'
import AppPreloader from '@/core/components/layout/AppPreloader'

import ErrorOverlay from '@/core/components/errors/ErrorOverlay'
import MaintenanceOverlay from '@/core/components/overlays/MaintenanceOverlay'
import ForceResetPasswordModal from '@/auth/components/modals/ForceResetPasswordModal'

export default {
  name: 'App',
  components: {
    AppWrapper,
    AppPreloader,
    ErrorOverlay,
    MaintenanceOverlay,
    ForceResetPasswordModal,
  },
  data() {
    return {
      initialized: false,
      maintenance: process.env.VUE_APP_MAINTENANCE_MODE === '1',
      authInterval: null,
    }
  },
  computed: {
    ...mapState({
      error: state => state.error,
    }),
  },
  beforeUnmount() {
    if (this.authInterval) {
      clearInterval(this.authInterval)
    }
  },
  async created() {
    try {
      const accessToken = storage.getAccessToken()
      const refreshToken = storage.getRefreshToken()

      if (accessToken && refreshToken) {
        this.$store.commit('auth/AUTH_SUCCESS', {
          access: accessToken,
          refresh: refreshToken,
        })

        this.$store.commit(
          'auth/SET_ACCESS_TOKEN_LAST_UPDATE',
          storage.getAccessTokenLastUpdate()
        )
        this.$store.commit(
          'auth/SET_REFRESH_TOKEN_LAST_UPDATE',
          storage.getRefreshTokenLastUpdate()
        )

        await this.$store.dispatch('auth/fetchAccount')
        const user = this.$store.state.auth.user
        if (!user.passwordExpired || moment() > moment(user.passwordExpired)) {
          this.$vfm.show('force-reset-password')
        }
      }
      this.authInterval = setInterval(async () => {
        const accessTokenLastUpdate = storage.getAccessTokenLastUpdate()
        if (accessTokenLastUpdate) {
          const delta = Math.floor(
            (Date.now() - storage.getAccessTokenLastUpdate()) / (1000 * 60)
          )
          if (+process.env.VUE_APP_BEARER_TOKEN_LIFETIME - delta === 2) {
            this.$vfm.show('check-activity')
          } else if (delta >= process.env.VUE_APP_BEARER_TOKEN_LIFETIME) {
            this.$vfm.hide('check-activity')
            await this.$store.dispatch('auth/logout')
          }
        }
      }, 60 * 1000)
    } catch (err) {
      handleErrors(err, this.$store)
    } finally {
      setTimeout(() => {
        this.initialized = true
      }, 300)
    }
  },
}
</script>
