import axios from 'axios'

import * as storage from '@/auth/helpers/storage'
import store from '@/core/store'

let refreshTokenRequest = null

const requestValidAccessToken = async () => {
  let { accessToken } = store.state.auth

  const now = Date.now()

  if (
    storage.getRefreshToken() &&
    !store.getters['auth/isRefreshTokenValid'](now)
  ) {
    storage.clearTokens()
    window.location.reload()
  } else if (!store.getters['auth/isAccessTokenValid'](now)) {
    if (refreshTokenRequest === null) {
      refreshTokenRequest = store.dispatch('auth/refresh')
    }

    accessToken = await refreshTokenRequest

    refreshTokenRequest = null
  }

  return accessToken
}

const axiosParams = {
  baseURL: `${process.env.VUE_APP_API_URL}/api/`,
}

const axiosInstance = axios.create(axiosParams)

axiosInstance.interceptors.request.use(async config => {
  if (config.skipAuth) {
    return config
  }

  const accessToken = await requestValidAccessToken()

  return {
    ...config,
    headers: {
      common: {
        ['Authorization']: `Bearer ${accessToken}`,
      },
    },
  }
})

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error && error.response && error.response.status === 401) {
      await store.dispatch('auth/logout')
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
