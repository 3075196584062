import api from './api'

export const fetchTechnologies = () => {
  return api.get('technologies/', { skipAuth: true })
}

export const getSalesTax = payload => {
  return api.post('common/sales_tax/', payload)
}

export const createPayment = payload => {
  return api.post('common/payment/', payload)
}

export const checkCoupon = code => {
  return api.post('common/check_coupon/', { code })
}

export const sendQuotesSurvey = (id, payload) => {
  return api.post(`quotes/my/${id}/answers/`, payload)
}

export const sendAccountAnswers = payload => {
  return api.post('accounts/my/answers/', payload)
}
